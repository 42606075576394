import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';

import { formColor } from '../../../../helper';
import SectionMedia from '../SectionMedia';

import Carousel from '../../../Carousel';

import styles from './styles.module.css';

class TestimonialsLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    const align = props.section.styles.align ? props.section.styles.align : 'Left';
    const full = props.section.styles.full === true ? 'Full' : '';

    let columns;
    if (props.section && props.section.styles
      && props.section.styles.columns) ({ columns } = props.section.styles);

    this.state = {
      full,
      align,
      columns,
    };

    this.createContent = this.createContent.bind(this);

  }

  createContent(item, index) {

    const smallStyle = `Small${this.props.themeData.typography.default.fontSize}`;
    let style;
    if (item.styles.backgroundColor.active) {

      let { backgroundColor } = item.styles;
      if (
        item.styles.backgroundColor.solid === ''
        && item.styles.backgroundColor.gradient.from === ''
      ) backgroundColor = { solid: '#ffffff' };

      style = formColor(
        backgroundColor,
        false,
        item.styles.backgroundColor.opacity,
      );

    }

    const align = `align${item.align}`;
    let cols;
    if (this.props.layout === 'design3') cols = 'col-12 col-md-8 offset-md-2';
    else if (this.state.columns === 1) cols = 'col-12';
    else cols = `col-12 col-sm-6 ${this.props.layout === 'design2' ? this.state.columns ? `col-md-${12 / this.state.columns}` : '' : `col-md-${this.state.columns ? 12 / this.state.columns : 4}`}`;

    const design = this.props.layout === 'design2' ? 'Design2' : this.props.layout === 'design3' ? 'Design3' : '';

    let texts = [];
    let image;
    item.data.forEach((elem, i) => {

      if (elem.active && elem.type.startsWith('PARAGRAPH/')) {

        let styleName;
        let content;
        if (elem.type === 'PARAGRAPH/PARAGRAPH') {

          styleName = `${styles.testimonialsComponentParagraph} ${styles[align]}`;
          content = HTMLParser(`<span>${elem.text}</span>`);

        } else {

          styleName = `${styles.testimonialsComponentSmall} ${styles[align]}`;
          content = (
            <span className={`${styles[smallStyle]} ${styles.exceptionWeight}`}>
              { HTMLParser(elem.text) }
            </span>
          );

        }

        const text = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={styleName}
          >
            { content }
          </div>
        );
        texts.push(text);

      } else if (elem.active && elem.type === 'IMAGES/IMAGE') {

        const move = elem && elem.content && elem.content.transform
          && (elem.content.transform.offsetX !== 0 || elem.content.transform.offsetY !== 0);

        let img = (
          <SectionMedia
            id={elem.content.id}
            mediaType={elem.content.icon ? 'ICON' : 'IMAGE'}
            wrapperStyle={this.props.layout === 'design3' ? 'testimonialsImgRound' : `imageContent${move ? 'Move' : ''}`}
            elementStyle={this.props.layout === 'design3' ? 'testimonialsImgRound' : ''}
            iconStyle=""
            align={item.align}
            src={elem.content.src}
            alt={elem.content.alt}
            data={elem.content}
            images={this.props.images}
            pagePathList={this.props.pagePathList}
            articlePathList={this.props.articlePathList}
            filePathList={this.props.filePathList}
            transform={elem.content ? elem.content.transform : undefined}
          />
        );

        if (design === 'Design3') {

          img = (
            <div>
              {img}
            </div>
          );

        }

        image = (
          <div
            key={`${this.props.section._id}_elem_${index}${i}`}
            className={`${styles[`testimonialsImageWrapper${design}`]}${design === 'Design3' ? ` ${styles[`align${item.align || 'Center'}`]}` : ''}`}
          >
            { img }
          </div>
        );

      }

    });

    if (this.props.layout === 'design2') {

      texts = (
        <div className={styles.design2TextWrapper} style={{ width: item.data[0].active ? '50%' : '100%' }}>
          { texts }
        </div>
      );

    }

    let content = (
      <React.Fragment>
        { image }
        { texts }
      </React.Fragment>
    );

    if (this.props.layout === 'design3') content = <div>{ content }</div>;

    return (<div
      key={`${this.props.section._id}_layout_${index}`}
      className={`${cols} ${this.props.layout !== 'design3' ? styles.colEqualHeight : styles.design3}`}
    >
      <div
        className={styles[`testimonialsComponentsWrapper${design}`]}
        style={style}
      >
        { content }
      </div>
    </div>);

  }

  render() {

    let activeComponents = [];
    const top = [];
    this.props.section.data.map((item, index) => {

      if (item.active && item.type === 'COMPONENT') {

        const content = this.createContent(item, index);
        activeComponents.push(content);

      } else if (item.active && item.type === 'HEADINGS/HEADING-TWO') {

        const text = (
          <div className={`col-12 ${styles.testimonialsMainHeader}`}>
            { HTMLParser(`<h2>${item.text}</h2>`) }
          </div>
        );
        top.push(text);

      }

      return null;

    });

    if (this.props.layout === 'design3') {

      activeComponents = (
        <div className="col-12 testimonialsCarousel">
          <Carousel
            showArrows
            showIndicators
            autoPlay={false}
            infiniteLoop={false}
          >
            { activeComponents }
          </Carousel>
        </div>
      );

    }

    const alignStyle = `align${this.state.align}`;

    return (
      <div className={`container ${styles[`testimonialsContainer${this.state.full}`]}`}>
        <div className={`row ${styles[alignStyle]}`}>
          { top }
          { activeComponents }
        </div>
      </div>
    );

  }

}

TestimonialsLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      align: PropTypes.string,
      full: PropTypes.bool,
    })),
  }),
};

export default TestimonialsLayout;
