import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.css';

class Carousel extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      moving: '',
      selected: 0,
      selectedBefore: 0,
      timer: null,
    };

    this.selectNext = this.selectNext.bind(this);

  }

  componentDidMount() {

    if (this.props.autoPlay) this.startTimer(this.props.interval);

  }

  componentWillUnmount() {

    if (this.state.timer) clearInterval(this.state.timer);

  }

  selectNext(e, direction) {

    if (e) e.stopPropagation();
    const selected = direction === 'R'
      ? (this.state.selected + 1) % this.props.children.length
      : this.state.selected < 1 ? this.props.children.length - 1 : this.state.selected - 1;

    if (this.props.autoPlay && !this.props.infiniteLoop
      && selected === this.props.children.length - 1) clearInterval(this.state.timer);

    this.setState({
      moving: direction,
      selected,
      selectedBefore: this.state.selected,
    });

  }

  startTimer(interval) {

    if (this.state.timer) clearInterval(this.state.timer);
    const timer = setInterval(() => this.selectNext(undefined, 'R'), interval);
    this.setState({
      timer,
    });

  }

  handleIndicatorClick(i) {

    this.setState({
      selected: i,
      moving: i < this.state.selected ? 'L' : i > this.state.selected ? 'R' : '',
      selectedBefore: this.state.selected,
    });

  }

  render() {

    const { moving, selected } = this.state;
    const childLength = this.props.children.length;
    const selectedItem = this.props.children[selected];
    const carouselContainer = styles[this.props.containerClass] || styles.carouselContainer;

    const prevItemStyle = styles[`prevCarouselItem${moving}`];
    const selectedItemStyle = styles[`selectedCarouselItem${moving}`];
    const nextItemStyle = styles[`nextCarouselItem${moving}`];
    const preloadItemStyle = styles.nextCarouselItem;

    let preloadItemIndex = moving === 'L' ? selected - 1 : selected + 1;
    if (preloadItemIndex >= this.props.children.length) preloadItemIndex = 0;
    else if (preloadItemIndex < 0) preloadItemIndex = this.props.children.length - 1;
    const preloadItem = this.props.children[preloadItemIndex];

    const indicators = this.props.children.map((c, i) => (
        <div
          className={this.state.selected === i
            ? styles.carouselIndicatorSelected : styles.carouselIndicator}
          key={`carousel-indicator-${i}`}
          onClick={() => this.handleIndicatorClick(i)}
        />
    ));

    return (
      <div className={this.props.mode === 'cards' ? styles.carouselContainerCards : carouselContainer}>
        {
          this.props.showArrows
            && (
              <button
                className={styles[!this.props.infiniteLoop && selected === 0
                  ? 'prevArrowContainerHidden' : 'prevArrowContainer']}
                onClick={e => this.selectNext(e, 'L')}
              >
                <div className={styles.prevArrow} />
              </button>
            )
        }
        {
          this.state.moving
          && (
            <div
              className={this.state.moving === 'L' ? nextItemStyle : prevItemStyle}
              key={`oItem-${moving}-${selected}`}
            >
              {this.props.children[this.state.selectedBefore]}
            </div>
          )
        }
        <div
          className={selectedItemStyle}
          key={`sItem-${moving}-${selected}`}
        >
          {selectedItem}
        </div>
        <div
          className={preloadItemStyle}
          key={`ssItem-${moving}-${selected}`}
        >
          {preloadItem}
        </div>
        {
          this.props.showArrows
          && (
            <button
              className={styles[!this.props.infiniteLoop && selected === (childLength - 1)
                ? 'nextArrowContainerHidden' : 'nextArrowContainer']}
              onClick={e => this.selectNext(e, 'R')}
            >
              <div className={styles.nextArrow} />
            </button>
          )
        }
        {
          this.props.showIndicators
          && (
            <div className={styles.carouselIndicatorContainer}>
              {indicators}
            </div>
          )
        }
      </div>
    );

  }

}

Carousel.propTypes = {
  children: PropTypes.arrayOf(PropTypes.shape({})),
  showArrows: PropTypes.bool,
  showIndicators: PropTypes.bool,
  autoPlay: PropTypes.bool,
  infiniteLoop: PropTypes.bool,
  interval: PropTypes.number,
  noEvents: PropTypes.bool,
  mode: PropTypes.string,
  containerClass: PropTypes.string,
};

export default Carousel;
