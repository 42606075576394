import React from 'react';
import PropTypes from 'prop-types';

import '../../../../pages/leaflet.css';

let L;

if (typeof window !== 'undefined') {

  L = require('leaflet/dist/leaflet');

}

import styles from './styles.module.css';

class OSMap extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      lat: this.props.lat,
      lng: this.props.lng,
      zoom: this.props.zoom,
      popupText: this.props.popupText,
      markerColor: this.props.markerColor,
    };

  }

  componentDidMount() {

    const tileAddress = 'https://maps.oidom.com/mapproxy/tiles/1.0.0/osm/EPSG900913/{z}/{x}/{y}.png';

    this.map = L.map(`map_${this.props.id}`, {
      center: [this.state.lat, this.state.lng],
      zoom: this.state.zoom,
      scrollWheelZoom: false,
      layers: [
        L.tileLayer(tileAddress, {
          attribution: '&copy; <a href="http://osm.org/copyright" >OpenStreetMap</a> contributors',
          tms: true,
        }),
      ],
    });

    const icon = new L.Icon.Default({
      imagePath: `${process.env.IMAGES_CDN}/leaflet/`,
      iconUrl: 'marker-icon.png',
      shadowUrl: 'marker-shadow.png',
      iconRetinaUrl: 'marker-icon-2x.png',
    });

    this.marker = L.marker([this.state.lat, this.state.lng], {
      icon,
    }).addTo(this.map);

    if (this.marker && this.state.popupText && this.state.popupText.length > 0) {

      this.marker.bindPopup(this.state.popupText);

    }

    setTimeout(() => {

      this.marker.openPopup();

    }, 1200);

  }

  render() {

    return (
      <div
        id={`map_${this.props.id}`}
        className={`${styles.font11pxImp} ${styles.mapStyle} ${styles.width100} ${styles.positionAbsolute}`}
      >
      </div>
    );

  }

}


OSMap.propTypes = {

  lat: PropTypes.number,
  lng: PropTypes.number,
  zoom: PropTypes.number,
  popupText: PropTypes.string,
  layout: PropTypes.string,
  markerColor: PropTypes.string,
  id: PropTypes.string,

};

export default OSMap;
