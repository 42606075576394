import React from 'react';
import PropTypes from 'prop-types';
import HTMLParser from 'html-react-parser';
import axios from 'axios';

import styles from './styles.module.css';

class SocialLayout extends React.PureComponent {

  constructor(props) {

    super(props);

    let embedCount = 0;

    const facebookProfile =
      props.section.data.filter(item => item.type === 'PROFILE/FACEBOOK')[0];
    const instagramProfile =
      props.section.data.filter(item => item.type === 'PROFILE/INSTAGRAM')[0];
    const twitterProfile =
      props.section.data.filter(item => item.type === 'PROFILE/TWITTER')[0];

    if (facebookProfile.active) embedCount += 1;
    if (instagramProfile.active) embedCount += 1;
    if (twitterProfile.active) embedCount += 1;

    this.state = {
      section: props.section,
      title: props.section.data.filter(i => i.type === 'HEADINGS/HEADING-TWO')[0],
      text: props.section.data.filter(i => i.type === 'PARAGRAPH/PARAGRAPH')[0],
      facebookProfile,
      instagramProfile,
      twitterProfile,
      embedCount,
      instagramData: [],
      fullWidth: props.section.styles.full,
      expires: instagramProfile.expires,
      instaUsername: instagramProfile.username,
      instaImageCount: instagramProfile.imageCount || 9,
    };

    this.InstaImages = this.InstaImages.bind(this);
    this.getInstagramData = this.getInstagramData.bind(this);

  }

  // eslint-disable-next-line class-methods-use-this
  loadTwitterScript() {

    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    script.async = true;

    document.body.appendChild(script);

  }

  getInstagramData(accessToken) {

    if ((this.state.instagramData && this.state.instagramData.length > 0)
      || !accessToken || accessToken.length < 8) {

      if (!this.componentIsMounted) {

        return;

      }

      this.setState({
        instagramData: [],
      });

      return;

    }

    if (this.state.expires) {

      const url = `https://graph.instagram.com/me/media?access_token=${accessToken}&fields=media_url,media_type,username,thumbnail_url`;

      axios.get(url).then((response) => {

        let username = this.props.instaUsername;

        response.data.data.forEach((d) => {

          if (d.username && d.username.length > 0) {

            // eslint-disable-next-line prefer-destructuring
            username = d.username;

          }

        });

        const instagramData = response.data.data
          .filter(i => (i.media_type === 'IMAGE' || i.media_type === 'VIDEO'))
          .slice(0, this.state.instaImageCount)
          .map(d => ({
            media_url: (d.media_type === 'IMAGE' ? d.media_url : d.thumbnail_url),
          }));

        this.setState({
          instagramData,
          instaUsername: username,
        });

      })
        .catch((e) => {

          console.log(e);

          this.setState({
            instagramData: [],
          });

        });

    } else { // backwards compatibility (only useful until march 31st 2020):

      const url = `https://api.instagram.com/v1/users/self/media/recent/?access_token=${accessToken}&count=${this.state.instaImageCount}`;

      if ((this.state.instagramData && this.state.instagramData.length > 0)
        || !accessToken || accessToken.length < 8) {

        if (!this.componentIsMounted) {

          return;

        }

        this.setState({
          instagramData: [],
        });

        return;

      }

      axios.get(url)
        .then((response) => {

          this.setState({
            instagramData: response.data.data,
          });

        })
        .catch((err) => {

          console.log(err);
          this.setState({
            instagramData: [],
          });

        });

    }

  }

  componentDidUpdate() {

    FB.XFBML.parse();

  }

  getTitle() {

    const text = `<h2>${this.state.title.text}</h2>`;

    return (
      <div>
        { HTMLParser(text) }
      </div>);

  }

  getText() {

    const text = `<span>${this.state.text.text}</span>`;

    return (
      <div>
        { HTMLParser(text) }
      </div>);

  }

  componentDidMount() {

    this.getInstagramData(this.state.instagramProfile.access_token);
    this.loadTwitterScript();

  }

  InstaImages() {

    const images = [];

    let wide = false;
    let width = 350;

    if (this.state.embedCount < 3 && this.props.matches) {

      width = 500;
      wide = true;

    }

    const link = `https://instagram.com/${this.state.instaUsername || this.state.instagramProfile.username}`;

    if (this.state.instagramProfile && this.state.instagramProfile.active
      && this.state.instagramData && this.state.instagramData.length > 0) {

      this.state.instagramData.forEach((data, instaIndex) => {

        const img = (<img
          key={`section_1_instaImage_${instaIndex + 1}`}
          alt=""
          data-index={instaIndex + 1}
          data-mediatype="IMAGE"
          data-disableicon
          style={{
            width: wide ? '154px' : '104px',
            height: wide ? '154px' : '104px',
            marginLeft: '3px',
            marginRight: '3px',
            marginTop: '3px',
            objectFit: 'cover',
          }}
          className="ImgStopAnchor"
          src={data.media_url || data.images.low_resolution.url}
        />);

        images.push(img);

      });

    }

    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className={styles.instagramLink}>
        <div
          style={{
            width: `${width}px`,
            height: wide ? '650px' : '500px',
            flexDirection: 'row',
            overflow: 'hidden',
            backgroundColor: '#FFFFFF',
            textAlign: 'center',
          }}
          className={styles.instaWrapper}
        >
          <div style={{
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: '16px',
            fontSize: '24px',
            fontWeight: 'bold',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: '0.17px',
            color: '#2a3a42',
          }}
          >Instagram
          </div>
          {images}
        </div>
      </a>);

  }

  render() {

    const scriptAddress = this.props.language === 'fi'
      ? 'https://connect.facebook.net/fi_FI/sdk.js#xfbml=1&version=v4.0'
      : 'https://connect.facebook.net/en_EN/sdk.js#xfbml=1&version=v4.0';

    let tabs = '';
    let first = true;

    if (this.state.facebookProfile.timeline) {

      tabs = 'timeline';
      first = false;

    }

    if (this.state.facebookProfile.events) {

      if (first) {

        tabs = 'events';

      } else {

        tabs = `${tabs},events`;

      }

      first = false;

    }

    if (this.state.facebookProfile.messages) {

      if (first) {

        tabs = 'messages';

      } else {

        tabs = `${tabs},messages`;

      }

    }

    let width = 350;
    let wide = false;

    if (this.state.embedCount < 3 && this.props.matches) {

      width = 500;
      wide = true;

    }

    return (
      <div className={this.state.fullWidth ? styles['socialContainerFull'] : styles['socialContainer']}>
        { this.state.title.active && this.getTitle() }
        { this.state.text.active && this.getText() }
        <div className={this.state.fullWidth ? styles.socialContentContainerFull : styles.socialContentContainer}>
          {this.state.facebookProfile.active &&
          <div
            key={`fbkey_${this.state.facebookProfile.timeline}${this.state.facebookProfile.events}
                ${this.state.facebookProfile.messages}${this.state.facebookProfile.hideCover}${this.state.facebookProfile.smallHeader}
                ${this.state.facebookProfile.showFacePile}${this.state.fullWidth}`}
            className={wide ? styles.facebookContainerWide : styles.facebookContainer}
          >
            <div id="fb-root" />
            <script
              async
              defer
              crossOrigin="anonymous"
              src={scriptAddress}
            />
            <div
              className="fb-page"
              data-tabs={tabs}
              data-href={`https://www.facebook.com/${this.state.facebookProfile.profile}`}
              data-hide-cover={this.state.facebookProfile.hideCover}
              data-small-header={this.state.facebookProfile.smallHeader}
              data-show-facepile={this.state.facebookProfile.showFacePile}
              data-height={wide ? 650 : 500}
              data-width={width} // 500 is max
              data-adapt-container-width="true"
            />
          </div>
          }
          {this.state.twitterProfile.active &&
          <div
            key={`twitter_profilekey_${this.state.twitterProfile.profile}`}
            className={wide ? styles.twitterContainerWide : styles.twitterContainer}
          >
            <a
              className="twitter-timeline"
              data-height={wide ? 650 : 500}
              data-width={width} // this is actually max width
              href={`https://twitter.com/${this.state.twitterProfile.profile}?ref_src=twsrc%5Etfw`}
            >{this.props.twitterProfile}
            </a>
          </div>
          }
          {this.state.instagramProfile.active && <this.InstaImages />}
        </div>
      </div>
    );

  }

}

SocialLayout.propTypes = {
  section: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      profile: PropTypes.string,
    })),
    _id: PropTypes.string,
  }),
};

export default SocialLayout;
